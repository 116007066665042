@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'variables';

html,
body {
    width: 100%;
    height: 100%;
    min-width: 1024px;
    overflow: hidden; //IE
    margin: 0;
    padding: 0;
}

html {
    box-sizing: border-box !important;
    font-size: $font-size-h6;
}

*,
*:after,
*:before {
    box-sizing: inherit !important; //correctly box-model setup
}

body {
    background: none !important;
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $gray-darker;

    .flex-container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .gray-light-container {
        padding: $padding-lg;
        border-radius: 2px;
        background-color: $gray-lighter;
        font-size: 12px;
    }

    .svg-host-button {
        cursor: pointer;
        background: transparent;
        border: none;
        outline: none;
        margin: 0;
        padding: 0;
        position: absolute;
    }

    a {
        text-decoration: none;
        color: $primary-color;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
            color: $primary-color-active;
        }
    }

    input {
        font-size: $font-size-base;
        padding: 2px;

        &::-ms-clear {
            display: none;
        }

        &[type='radio'],
        &[type='checkbox'] {
            cursor: pointer;
        }

        &[type='radio'],
        &[type='checkbox'],
        &[type='text'] {
            &[disabled] {
                cursor: not-allowed;
            }
        }
    }

    textarea {
        font-size: $font-size-base;
        font-family: $font-family-base;
    }

    label[for] {
        cursor: pointer;
    }

    h1 {
        float: left;
        margin: 0;
        padding: 2px 0;
        font-size: 16px;
    }

    #dim-around-element {
        background: $gray-darker;
        opacity: 0.6;
        position: fixed;
        top: 90px;
        left: 0;
        bottom: 0;
        right: 0;
    }

    li {
        list-style: none;
    }
}
