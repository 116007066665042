// paddings/margins
$padding-xs: 4px;
$padding-sm: 8px;
$padding-md: 12px;
$padding-lg: 16px;
$padding-xl: 20px;
$padding-xxl: 24px;

// borders
$border-width: 1px;
$border-radius: 2px;
$border-radius-small: 1px;
$border-radius-base: 2px;
$border-radius-large: 3px;
$border-radius-xlarge: 4px;

$lighter-gray-color: #eaeef3;
$light-gray-color: #c1c5ca;
$gray-color: #84888d;
$dark-gray-color: #53575c;
$darker-gray-color: #32363b;
$gray-background: #f0f1f2;
$white-color: #fff;
$color-warning: #f7941d;
$color-error: #ed2b23;

$primary-color: #0066b3;
$primary-color-active: #0b507f;

$row-hover-bg: #e2e3e4;
$row-selected-bg: #c9dde1;

$box-shadow: 2px 2px 2px 0 rgba(193, 197, 202, 0.3);

//buttons
$button-color: $white-color;
$button-background: $primary-color;
$button-background-hover: $primary-color-active;
$button-border-radius: $border-radius;

// Colors for Header, Footer, Dialog Headers, Dropdown,Calendar
$ihs-color-h1: #0066b3;
$ihs-color-h2: #0b507f;
$ihs-color-h3: #d2e3e9;
$ihs-color-h4: #a7d6f3;
$ihs-color-h5: #1b8fc9;
$ihs-color-h6: #6d6e70;
$ihs-color-h7: #f1f1f2;
$ihs-color-h8: #fff;

//Colors for selectors, form field and disabled buttons
$ihs-color-o1: #dbdcde;
$ihs-color-o2: #a1abb2;
$ihs-color-o3: #fff;
$ihs-color-o4: #f1f1f2;

//Colors for buttons (normal / emphasis)
$ihs-color-bn1: #0066b3;
$ihs-color-bn2: #0b507f;
$ihs-color-bn3: #a7d6f3;
$ihs-color-be1: #f04e23;
$ihs-color-be2: #c6411e;
$ihs-color-bg1: #bdcf56;
$ihs-color-bg2: #a3af4a;

// General colors for text and else
$ihs-color-g1: #0066b3;
$ihs-color-g2: #0b507f;
$ihs-color-g3: #a7d6f3;
$ihs-color-g4: #1b8fc9;
$ihs-color-g5: #404041;
$ihs-color-g6: #6d6e70;
$ihs-color-g7: #dbdcde;
$ihs-color-g8: #f1f1f2;
$ihs-color-g9: #fff;
$ihs-color-g10: #c8e7f5;
$ihs-color-g11: #f1f3bc;
$ihs-color-g12: #b54f9d;
$ihs-color-g13: #d2e3e9;
$ihs-color-g14: #490882;
$ihs-color-g15: #000;
$ihs-color-g16: #ed2b23;

//Colors for Workspace Tiles
$ihs-color-t1: #96bb3c;
$ihs-color-t2: #c66;
$ihs-color-t3: #999;
$ihs-color-t4: #72b7b5;
$ihs-color-t5: #d87755;
$ihs-color-t6: #00a8ca;
$ihs-color-t7: #666;
$ihs-color-t8: #099;

//Colors for Notifications
$ihs-color-n1: #cae9f3;
$ihs-color-n2: #fcdebe;
$ihs-color-n3: #cfe4c2;
$ihs-color-n4: #ecc9c6;

//Colors for Highlighting
$ihs-color-d1: #d6e6f6;
$ihs-color-d2: #faddbd;
$ihs-color-d3: #cfe3c2;
$ihs-color-d4: #ebc9c6;
$ihs-color-d5: #eeebbe;
$ihs-color-d6: #f1f1f2;
$ihs-color-d7: #e1cfe5;

$ihs-color-d1a: #f5eb1e;
$ihs-color-d2a: #65bc45;
$ihs-color-d3a: #008180;
$ihs-color-d4a: #54caf5;
$ihs-color-d5a: #374da0;
$ihs-color-d6a: #7e287f;
$ihs-color-d7a: #ed2b23;
$ihs-color-d8a: #f58538;
$ihs-color-d9a: #cfc0c0;
$ihs-color-d10a: #5e3508;

$ihs-color-d1b: #f7f2a2;
$ihs-color-d2b: #cfe3c2;
$ihs-color-d4b: #d6e6f6;
$ihs-color-d6b: #e1cfe5;
$ihs-color-d7b: #ebc9c6;
$ihs-color-d8b: #faddbd;
$ihs-color-d9b: #f1f1f2;
$ihs-color-d10b: #b58d6d;

//Fonts
$font-family-sans-serif: Arial, Helvetica, sans-serif;
$font-family-serif: Brioni, Georgia, 'Times New Roman', Times, serif;
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;
$font-size-base: 12px;
$font-size-large: ceil(($font-size-base * 1.25));
$font-size-small: ceil(($font-size-base * 0.83));
$font-size-h1: ceil(($font-size-base * 1.4));
$font-size-h2: ceil(($font-size-base * 1.15));
$font-size-h3: ceil(($font-size-base * 1.05)); // 14px
$font-size-h4: ceil(($font-size-base * 0.95)); // $font-size-base
$font-size-h5: ceil(($font-size-base * 0.9)); // $font-size-base
$font-size-h6: ceil(($font-size-base * 0.85)); //

$line-height-base: 1.4em;
$line-height-footer: 1.6em;

$body-bg: #ffffff;
$gray-darker: #404041;
$gray-dark: #6d6e70;
$gray: #a1abb2;
$gray-light: #dbdcde;
$gray-lighter: #f1f1f2;

// -- Header
$headerBGColor: $body-bg;
$headerTextColor: #4c4b4c;
$headerMinWidth: 1024px;
$headerHeight: 56px;
$tabMenuHeight: 24px;
$navIconItemHeight: 66px;
$navIconItemWidth: 66px;
$logoPaddingTB: $padding-xs;
$logoMargin: $padding-xs - 2;

// -- footer
$footerHeight: 64px;
$footerBGColor: #4c4b4c;
$footerTextColor: $body-bg;
$footerPaddingLR: 14px;
$footerPaddingTB: 16px;
$footerMinWidth: $headerMinWidth;

// -- Colors for grid header
$ihs-color-gh1: #fff2cc;
$ihs-color-gh2: #e2efda;
